
























































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Playlist} from '@/types';
import UTable, {TableField} from '@/ui-lib/table/Table.vue';
import {preset} from "@/store/modules/preset";
import {convertTimeHHMMSS} from "@/utils/convertTimeHHMMSS";
import {RawLocation} from "vue-router";

@Component<PlaylistTableList>({
  components: {
    UTable,
  }
})
export default class PlaylistTableList extends Vue {
  @Prop({required: true, type: Array})
  private list!: Playlist[];

  private get presets() {
    return preset.context(this.$store).state.list;
  }

  private get fields(): TableField[] {
    return [
      {
        name: 'id',
        label: 'ID',
        width: '65px',
        sortable: true,
      },

      {
        name: 'image',
        label: '',
        width: '60px',
      },

      {
        name: 'title',
        label: 'Название',
      },

      {
        name: 'musicPresetIDs',
        label: 'Пресеты',
        width: '170px',
      },

      {
        name: 'totalTracks',
        label: 'Треки',
        align: 'right',
        width: '100px',
        sortable: true,
      },

      {
        name: 'totalDuration',
        label: 'Длительность',
        align: 'right',
        width: '150px',
        sortable: true,
      },
    ];
  }

  private presetsName(pl: Playlist) {
    return this.presets.filter((g) => pl.musicPresetIDs.includes(g.id)).map((g) => g.name).join(', ') || 'Неизвестно';
  }

  private durationText(pl: Playlist) {
    return convertTimeHHMMSS(pl.totalDuration);
  }

  private itemRoute(pl: Playlist): RawLocation {
    return {
      name: 'playlistItem',
      params: {
        id: pl.id.toString(),
      },
    };
  }
}
